const INFO = {
	main: {
		title: "Marvin's Portfolio",
		name: "Tharindu N.",
		email: "marvin.vino210@protonmail.com",
		logo: "../logo.png",
	},

	socials: {
		github: "https://github.com/noob210",
		linkedin: "https://www.linkedin.com/in/marvin-alingasa/",
		instagram: "https://www.instagram.com/marvenom_/",
		facebook: "https://www.facebook.com/marvino210",
	},

	homepage: {
		title: "Full-stack developer",
		description:
			"Hello there! I'm Marvin Alingasa, a passionate and results-driven full-stack developer with a flair for crafting innovative digital solutions. With a strong foundation in both front-end and back-end technologies, I bring a holistic approach to system development.",
	},

	about: {
		title: "I’m Marvin Alingasa I live in Philippines.",
		description:
			"With a passion for solving complex problems, I specialize in backend development, where I bring ideas to life through robust server-side solutions. My commitment to excellence and love for technology drive me to create seamless, efficient, and scalable systems.",
	},

	articles: {
		title: "I'm passionate about pushing the boundaries of what's possible and inspiring the next generation of innovators.",
		description:
			"Chronological collection of my long-form thoughts on programming, leadership, product design, and more.",
	},

	projects: [
		{
			title: "Paste It Online",
			description:
				"Easy way to share text with friends. Built with Bootstrap 5 for Frontend, Python (Flask) for Backend, and MongoDb for Database.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/python/python.png",
			linkText: "View Project",
			link: "http://pasteit.online",
		},

		{
			title: "Mimir AI",
			description:
				"GPT-4 Tool for MS Word and Excel. Desktop app was made with C# (WPF), Backend and API was made using Python(Flask) and MongoDb as Database.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/python/python.png",
			linkText: "View Project",
			link: "https://mimirapp-ai-test-484211d5f6eb.herokuapp.com/",
		},

		{
			title: "Simple Chatbot Using Intent-Recognition",
			description:
				"Simple chatbot built using Python with Intent-Recognition",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/python/python.png",
			linkText: "View Project",
			link: "https://mimir-ai-5cd12058379c.herokuapp.com/",
		},

		{
			title: "Pharmacy Inventory System",
			description:
				"Pharmacy Inventory System written using PyQt5 and MySQL for Database.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/python/python.png",
			linkText: "View Screenshots",
			link: "https://imgur.com/a/H8P609f",
		},

		{
			title: "RFID-Based Time Logger",
			description:
				"Automated attendance and time-logger for school. Frontend was writtend with HTML,CSS,JS,Bootstrap and JQuery, Python(Flask) for Backend, and SQLite3 for Database.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/python/python.png",
			linkText: "View Screenshots",
			link: "https://imgur.com/a/8hlHCUm",
		},
	],
};

export default INFO;
